<template>
  <CCard>
    <CCardHeader>
      <strong>Benachrichtigungen verwalten</strong>
    </CCardHeader>
    <CCardBody>
      <CForm @submit.prevent="submitNotification">
        <CInput
          label="Titel"
          v-model="newNotification.title"
          placeholder="Geben Sie den Titel der Benachrichtigung ein"
          required
        />
        <CTextarea
          label="Beschreibung"
          v-model="newNotification.description"
          placeholder="Geben Sie die Beschreibung der Benachrichtigung ein"
          rows="3"
          required
        />
        <CSelect
          label="Priorität"
          :options="priorityOptions"
          v-model="newNotification.priority"
        />
        <CInput
          label="Gültig bis"
          type="datetime-local"
          v-model="newNotification.expiresAt"
        />
        <CButton color="primary" type="submit">Benachrichtigung erstellen</CButton>
      </CForm>

      <CDataTable
        :items="formattedNotifications"
        :fields="fields"
        hover
        striped
        :items-per-page="5"
        :pagination="{ doubleArrows: false, align: 'center' }"
      >
        <template #actions="{ item }">
          <CButton
            color="danger"
            size="sm"
            @click="deleteNotification(item.id)"
          >
            Löschen
          </CButton>
        </template>
      </CDataTable>
    </CCardBody>
  </CCard>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import moment from 'moment';
import WebSocketService from '@/services/WebSocketService';
import axios from 'axios';
import toastPlugin from '@/toastPlugin';

export default {
  name: 'NotificationManagement',
  created() {
    this.initWebSocket();
  },
  data() {
    return {
      newNotification: {
        title: '',
        description: '',
        priority: 'normal',
        expiresAt: ''
      },
      priorityOptions: [
        { value: 'low', label: 'Niedrig' },
        { value: 'normal', label: 'Normal' },
        { value: 'high', label: 'Hoch' }
      ],
      fields: [
        { key: 'title', label: 'Titel' },
        { key: 'description', label: 'Beschreibung' },
        { key: 'priority', label: 'Priorität' },
        { key: 'createdAt', label: 'Erstellt am' },
        { key: 'expiresAt', label: 'Gültig bis' },
        { key: 'actions', label: 'Aktionen' }
      ]
    };
  },
  computed: {
    ...mapState({
      notifications: state => state.notifications.notifications
    }),
    formattedNotifications() {
      if (Array.isArray(this.notifications)) {
        return this.notifications.map(notification => ({
          id: notification.id,
          title: notification.title,
          description: notification.description,
          priority: notification.priority,
          createdAt: moment(notification.createdAt).format('LLL'),
          expiresAt: notification.expiresAt ? moment(notification.expiresAt).format('LLL') : 'Nicht gesetzt',
          isRead: notification.isRead
        }));
      } else {
        console.error('Expected notifications to be an array, but got:', this.notifications);
        return [];
      }
    }
  },
  methods: {
    ...mapActions(['fetchNotifications', 'addNotification', 'markAsRead', 'removeNotification', 'createNotification']),
    
    async submitNotification() {
  try {
    const notification = {
      ...this.newNotification,
      expiresAt: this.newNotification.expiresAt ? moment(this.newNotification.expiresAt).toISOString() : null
    };
    console.log('Dispatching createNotification with:', notification);
    await this.createNotification(notification);
    this.resetForm();
    await this.fetchNotifications();
  } catch (err) {
    console.error('Error creating notification:', err);
    toastPlugin.makeToast('Fehler', 'Fehler beim Erstellen der Benachrichtigung.');
  }
},


    markAsRead(notificationId) {
      axios.put(`/api/notifications/${notificationId}/read`)
        .then(response => {
          if (response.status === 200) {
            this.$store.commit('MARK_AS_READ', notificationId); // Vuex-Store aktualisieren
            this.fetchNotifications(); // Aktualisieren Sie die Benachrichtigungen nach dem Lesen
            toastPlugin.makeToast('Erfolg', 'Benachrichtigung als gelesen markiert.');
          }
        })
        .catch(err => {
          console.error('Error marking notification as read:', err);
          toastPlugin.makeToast('Fehler', 'Fehler beim Markieren der Benachrichtigung als gelesen.');
        });
    },

    deleteNotification(id) {
      console.log('Dispatching deleteNotification with ID:', id); // Debugging
      this.$store.dispatch('deleteNotification', id).catch(err => {
        console.error('Error deleting notification:', err);
      });
    },

    resetForm() {
      this.newNotification = {
        title: '',
        description: '',
        priority: 'normal',
        expiresAt: ''
      };
    },

    initWebSocket() {
      WebSocketService.connect();
      WebSocketService.subscribeToTopic('/topic/mailOpened', (message) => {
        const leadId = message.body;
        this.handleMailOpened(leadId);
      });
      WebSocketService.subscribeToTopic('/topic/notifications', (message) => {
        const notificationData = JSON.parse(message.body);
        this.handleNewNotification(notificationData);
      });
    },

    handleMailOpened(leadId) {
      const notification = {
        title: `E-Mail für Lead ${leadId} geöffnet`,
        description: `Eine E-Mail für Lead ${leadId} wurde geöffnet.`,
        priority: 'normal',
        createdAt: new Date().toISOString()
      };
      this.addNotification(notification);
    },

    handleNewNotification(notificationData) {
      const notification = {
        ...notificationData,
        createdAt: new Date().toISOString()
      };
      this.addNotification(notification);
    }
  },
  mounted() {
    console.log('NotificationManagement component mounted'); // Debugging
    this.fetchNotifications().then(() => {
      console.log('Fetched notifications:', this.notifications);
    }).catch(err => {
      console.error('Error fetching notifications:', err);
    });
  }
};
</script>



<style scoped>
.card-body {
  padding: 1.25rem;
}

.form-group {
  margin-bottom: 1rem;
}

.btn-primary {
  margin-top: 1rem;
}

.table {
  margin-top: 2rem;
}
</style>
